<template>
  <div>
    <div v-if="isLoading">
      <Loader />
    </div>
    <div v-else>
      <TopMenuSubheadline :title="title" />
      <div class="app-content-box bg-gray pb-1">
        <ul class="category-collapse-list wrapper">
        <div v-for="(item, key) in genres" :key="key">
          <li v-b-toggle="'cat-' + key">
            <div class="container">
              <a class="category-element" :class="{'category-collapse-link': item.children.length,  'category-element-checked': isCatChecked.filter(function(el) {return item.label == el}).length > 0}" :id="'id-' + key" data-toggle="collapse" >
                <router-link :to="{name: 'TagsDetails', params: {id: item.id}}"><a class="category-element">{{item.label}}</a></router-link>
              </a>
            </div>
          </li>
          <b-collapse :id="'cat-' + key">
          <div>
              <div v-if="item.children" class="collapse category-collapse-content show" >
                <ul>
                  <li v-for="(sub, i) in item.children" :key="i">
                    <router-link :to="{name: 'TagsDetails', params: {id: sub.id}}">{{sub.label}}</router-link>
                  </li>
                </ul>
              </div>
          </div>
        </b-collapse>
        </div>
        </ul>
            <!-- <div class="category-btn bg-gray" @click="$router.go(-1)"><button class="btn-black-full w-100 mt-2 mb-3">Pokaż wyniki</button></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
export default {
  name: 'Categories',
  components: {
    TopMenuSubheadline,
    Loader
  },
  data () {
    return {
      genres: [],
      title: 'Wszystkie kategorie',
      isCatChecked: [],
      isSubChecked: [],
      addBookSelectedGenres: [],
      isLoading: true
    }
  },
  async created () {
    this.selectedGenres = []
    await this.$https('/genres', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {

      }
    })
      .then(res => {
        this.genres = res.data.response
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
  },
  computed: {
    selectedGenres: {
      get () {
        return this.$store.state.addBookSelectedGenres
      },
      set (value) {
        this.$store.commit('updateAddBookSelectedGenres', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    padding-top: 40px;
    margin-bottom: 20px;
  }
  .category-element {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: block;
      color: #000;
  }
  .collapse .category-collapse-content {
    a {
      color: #000;
      font-weight: 400;
    }
  }

  .category-collapse-list li {
    border-bottom: none;
  }
  .category-element-checked {
    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 12px;
        content: "\f00c";
        margin-right: 5px;
    }
  }
  .category-btn {
    position: fixed;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    max-width: 360px;
  }
</style>
